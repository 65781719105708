import { useLottie, useLottieInteractivity } from "lottie-react"
import lottieJson from '../../lottie/animation_ki0ftp70.json'

const options = {
    animationData: lottieJson,
};

const style = {
    height: 500,
}

const HagFlag = () => {
    const lottieObj = useLottie(options,style);
    const Animation = useLottieInteractivity({
        lottieObj,
        mode: "scroll",
        width: "100",
        actions: [
            {
                visibility: [0.4, 0.9],
                type: "seek",
                frames: [0, 250],
            },
        ],
    });
    return Animation;
};

export default HagFlag