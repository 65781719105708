import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'

const PurpleCircles = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      purpleCircles: file(relativePath: { eq: "about-us/purple_circles.png" }) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)
  const imageData = data.purpleCircles.childImageSharp.fluid
  return (
    <BackgroundImage
      Tag="section"
      fluid={imageData}
      style={{
        width: `80%`,
        backgroundSize: `contain`,
        padding: `5em`,
        marginLeft: `4em`
      }}
    >
      {children}
    </BackgroundImage>
  )
}

export default PurpleCircles