import React from "react"
import Layout from "../components/layout"
import Menu from "../components/menu"
import { Row, Col } from "react-bootstrap"
import Body from "../components/body"
import Styled from "styled-components"
import VerticalTitle from "../components/verticaltitle"
import Form from "../components/form"
import Footer from "../components/footer"
import DotsLine from "../components/lottie-components/dots_line"
import StreetMovement from "../components/about-us-images/street_movement"
import PurpleCircles from "../components/about-us-images/purple_circles"
import RodrigoAlvarenga from "../components/about-us-images/rodrigo_alvarenga"
import Globe from "../components/about-us-images/globe"
import DNA from "../components/about-us-images/dna"
import HAGFlag from "../components/lottie-components/hag_flag"
import Lamp from "../components/about-us-images/lamp"
import BluePrint from "../components/about-us-images/blueprint"
import MakingProject from "../components/about-us-images/making_project"
import Bridge from "../components/about-us-images/bridge"
import BarsGraph from "../components/about-us-images/bars_graph"
import Highlight from "../components/highlight"
import BorderedTitle from "../components/bordered_title"
import LazyLoad from "react-lazyload"

const BorderedSubtitle = Styled.div`
border-bottom: solid 1px black;
height: 1px;
margin-bottom: 1em;
margin-top: 1em;
margin-left: auto;
margin-right: auto;
width: 50%;
`

const ApproachTitle = Styled.h2`
position: absolute;
margin-top: 2em;
z-index: 1;
`

const Team = Styled.div`
background-color: #485696;
min-height: 100vh;
padding: 10vw;
`

const TeamTopics = Styled(Row)`
p{
  font-size: 1em;
}
h3{
  font-size: 1.3em;
}
`

const Goal = Styled.div`
padding: 5%;
min-height: 100 vh;
`

const IndexPage = () => (
  <Layout style={{ backgroundColor: `black`, color: `#ddd` }}
    lang="en" title="HAG Ventures" link="https://www.hag.ventures/about" description="HAG Ventures is a boutique that combines skills like consulting services, education, startups' creation and angel investment.">
    <Menu
      MenuItem1={
        <Body>
          <Highlight>
            <Row>
              <Col sm="12" md="2">
                <VerticalTitle style={{ fontWeight: `bold`, color: `white` }}>
                  about
            </VerticalTitle>
              </Col>
              <Col sm="12" md="10" style={{ padding: `10vw` }}>
                <h2><b>WHO WE ARE<br /> <span style={{ color: `#485696`, fontSize: `0.7em` }}>& WHAT WE VALUE.</span></b></h2>
                <p>We are a company that understands the value of people and the uniqueness that the <span className="text-highlight-purple">human factor</span> confers to any business endeavour.</p>
              </Col>
            </Row>
            {/* Coloquei uma altura mínima porque há lazy load nesse componente mudando a altura do viewport para que o highlight pegue a altura correta*/}
            <div style={{ height: `50vh` }}>
              <LazyLoad offset={100}>
                <DotsLine />
              </LazyLoad>
            </div>
            <Row style={{ padding: `10vw` }}>
              <Col sm="12" md={{ offset: 2, span: 10 }} >
                <h2><b>WHAT WE DO<br /> <span style={{ color: `#485696`, fontSize: `0.7em` }}>& WHAT WE VALUE.</span></b></h2>
                <p>We put people at our centre as we devise fresh and innovative strategies that utilise the latest know-how, <span className="text-highlight-purple">technologies</span> and agile methodologies to aid in the process of business transformation.</p>
              </Col>
            </Row>
            <StreetMovement>
              <div style={{ padding: `10vw` }}>
                <BorderedTitle>
                  <h2><b>HOW WE DO IT</b></h2>
                </BorderedTitle>
                <Row style={{ marginTop: `2em` }}>
                  <Col sm="12">
                    <p>
                      We work closely with companies to understand their values and goals so that together we reach them and deliver real, satisfying results.
              </p>
                  </Col>
                  <Col sm="12">
                    <p>
                      In order to maximise business transformation and development, we apply Business Design, which not only puts the customer at the centre but
                      also takes into consideration profitable business models based on the combination of the human capital, technology, innovation and impact.
              </p>
                  </Col>
                </Row>
              </div>
            </StreetMovement>
            <div style={{ padding: `10vw`, margin: `auto`, marginBottom: `5em` }}>
              <ApproachTitle><b>OUR APPROACH</b></ApproachTitle>
              <PurpleCircles />
              <p>
                This human-centred approach ensures that your customers have a <span className="text-highlight-purple">unique</span> experience interacting with your brand while having their needs met.
                Which, in turn, can be profitable, contribute to your company’s growth and also generating a positive impact (ESG).
            </p>
            </div>
            <Team>
              <Row style={{ marginBottom: `4em` }}>
                <Col md="8" sm="12">
                  <h2 style={{ color: `black` }}><b>OUR TEAM</b></h2>
                  <p>Our team is composed of highly qualified and <span className="text-highlight-black">skilled members</span> who work together to ensure that we help you get where you and your brand need to be.</p>
                </Col>
                <Col md="4" sm="12" style={{ paddingRight: `5vw`, paddingLeft: `5vw` }}>
                  <RodrigoAlvarenga />
                  <p style={{ textAlign: `center`, fontSize: `1em`, marginTop: `1em` }} >Rodrigo de Alvarenga <br /> <b>CEO</b></p>
                </Col>
              </Row>
              <TeamTopics style={{ textAlign: `center` }}>
                <Col sm="12" md="3">
                  <h3>
                    DISCOVER
                  </h3>
                  <BorderedSubtitle />
                  <p>
                    Data Science <br />
                    Business Intelligence
                  </p>
                </Col>
                <Col sm="12" md="3">
                  <h3>
                    RESHAPE
                  </h3>
                  <BorderedSubtitle />
                  <p>
                    Business Transformation <br />
                    Digital Transformation
                  </p>
                </Col>
                <Col sm="12" md="3">
                  <h3>
                    REVOLUTIONIZE
                  </h3>
                  <BorderedSubtitle />
                  <p>
                    Innovation <br />
                    Corporate Venture
                  </p>
                </Col>
                <Col sm="12" md="3">
                  <h3>
                    DISRUPT
                  </h3>
                  <BorderedSubtitle />
                  <p>
                    Startup Studio <br />
                    Business Design
                  </p>
                </Col>
              </TeamTopics>
            </Team>
            <Goal>
              <Row className="align-items-center">
                <Col sm="12" md="6">
                  <Globe style={{ width: `30vw` }} />
                </Col>
                <Col sm="12" md="6">
                  <h2><b>OUR GOAL</b></h2>
                  <p>As a specialised company in consultancy services, we can help you build, scale, and access <span className="text-highlight-purple">global markets.</span></p>
                  <p>We put a premium on creativity & innovation, and such qualities characterise our approach.</p>
                  <p>Whether your company seeks to break into the global market, or you are venturing out in a new business, we will get you there.</p>
                </Col>
              </Row>
            </Goal>
            <DNA>
              <div style={{ padding: `10vw` }}>
                <h2><b>OUR UNIQUE OFFERINGS</b></h2>
                <div style={{ fontSize: `20px` }}>
                  <p>By anchoring our strategy on people, we are best able to utilise their dynamic qualities, resulting in new mindsets and innovative ideas.</p>
                  <p>Combined with our know-how and agile methodologies, this means we can help you develop the ideal environment to maximise your potential and nurture long-term growth for you and your company.</p>
                </div>
              </div>
            </DNA>
            <div style={{ height: `100vh` }}>
              <div style={{ position: `absolute`, padding: `10vw`, zIndex: `1` }}>
                <LazyLoad offset={100}>
                  <HAGFlag style={{ height: `20em` }} />
                </LazyLoad>
              </div>
              <div style={{ position: `relative`, padding: `10vw`, zIndex: `2` }}>
                <h2><b>HAG STUDIO</b></h2>
                <p>At HAG Studio, we provide guidance and resources to help you create and manage innovative and scalable businesses & startups.</p>
                <p>We are more than just an accelerator program since we offer our expertise and support from the initial idea stage up until the scaling stage. Through this greater involvement and our business design program, we revolutionise the way startups are created and managed.</p>
              </div>
            </div>
            <div style={{ backgroundColor: `#485696` }}>
              <BluePrint>
                <div style={{ padding: `10vw` }}>
                  <h2><b>HAG SERVICES</b></h2>
                  <p>Innovation and uniqueness are what can help put companies ahead of their competitors. To this end, we strive to make <span className="text-highlight-black">evidence-based decisions</span> through experimentation, collaborate closely with multidisciplinary teams for quick and practical work, and focus on solution viability.</p>
                  <p>We provide companies with the necessary tools, ideas, and direction to improve the way they innovate.</p>
                </div>
              </BluePrint>
            </div>
            <MakingProject>
              <div style={{ padding: `10vw` }}>
                <h2><b>HAG ACADEMY</b></h2>
                <Row>
                  <Col md="6" sm="12">
                    <p>At HAG Academy, we believe that the best way to achieve the optimum potential of any organisation or company is through a combination of education and transformation.</p>
                  </Col>
                  <Col md="6" sm="12">
                    <p>We offer proprietary programs to support university students, entrepreneurs, and professionals in re-skilling and up-skilling. We equip them with the knowledge and tools so they can assume the role of effecting business transformation.
                After they graduate from our programs, they are in a better position to become changers and protagonists in the world today.</p>
                  </Col>
                </Row>
              </div>
            </MakingProject>
            <Row style={{ padding: `10vw` }} className="align-items-center">
              <Col sm="12" md="4">
                <Lamp style={{ width: `15vw`, margin: `none` }} />
              </Col>
              <Col sm="12" md="8">
                <h2><b>OUR MISSION</b></h2>
                <p>In all of our offerings, we also believe in the power of connections: <span className="text-highlight-purple">connecting people</span> and ideas, as well as connecting companies to the most critical global markets.</p>
              </Col>
            </Row>
            <Bridge>
              <div style={{ padding: `10vw` }}>
                <h2><b>OUR STRATEGY</b></h2>
                <p>Our strategies and services include utilising and maximising these connections. With these, we help propel entrepreneurs, startups, and established enterprises into a successful international business and impact-driven entrepreneurship.</p>
              </div>
            </Bridge>
            <div style={{ padding: `10vw` }}>
              <h2><b>OUR CLIENTS</b></h2>
              <p>We are geared towards helping startups, small and medium enterprises (SME) as well as students and professionals in venture building, corporate venture, business and digital transformation, and finding paths for personal and professional growth.</p>
              <Row className="align-items-center">
                <Col md="6" sm="12">
                  <BarsGraph />
                </Col>
                <Col md="6" sm="12">
                  <p>Whether partnering up with us through HAG Studio, HAG Services, or HAG Academy, we help you and your company achieve your goals and objectives.</p>
                </Col>
              </Row>
            </div>
            <LazyLoad offset={100}>
              <Form />
            </LazyLoad>
            <Footer />
          </Highlight>
        </Body>
      }
      Titles={["services", "studio", "academy"]}
      Links={["/", "https://www.hag.services", "https://www.hag.studio", "https://www.hag.academy"]}
    ></Menu>
  </Layout >
)

export default IndexPage
