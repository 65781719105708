import React from "react"
import Styled from "styled-components"
import { Row, Col } from "react-bootstrap"
import LightLogo from "./lightlogo"

const Address = Styled.div`
position: relative;
float: right;
font-size: 0.7em;
text-align: right;
p {
margin-bottom: 0px;
}
`

const FormSections = Styled.div`
margin: auto;
padding-left: 30px;
padding-right: 30px;
@media(min-width: 768px){
    width: 860px;
}
@media(max-width: 768px){
    width: 100%;
}
`

export default () =>
    <div>
        <FormSections>
            <h2 style={{ color: `#ddd` }}>
                talk to us</h2>
        </FormSections>
        <iframe title="HAG Ventures Contact Form" frameBorder="0" style={{ height: `700px`, width: `100%`, border: `none ` }}
            src='https://forms.zohopublic.com/haggroup/form/Websites/formperma/XWOFn3eA_13k0BTCGUZiWHjmM04S509FhoxBwZ39wJs'>
        </iframe>
        <FormSections>
            <Row>
                <Col>
                    <LightLogo style={{ position: `relative`, width: `3em` }} />
                </Col>
                <Col>
                    <Address>
                        <p>HAG Europe</p>
                        <p>HAG USA</p>
                        <p>HAG LATAM</p>
                    </Address>
                </Col>
            </Row>
        </FormSections>
    </div>