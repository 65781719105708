import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const RodrigoAlvarenga = ({ style }) => {
    const data = useStaticQuery(graphql `
    query {
      rodrigoAlvarenga: file(relativePath: { eq: "about-us/rodrigo_alvarenga.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)
    return <Img style = { style }
    fluid = { data.rodrigoAlvarenga.childImageSharp.fluid } alt="Rodrigo de Alvarenga"
    />
}

export default RodrigoAlvarenga