import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Lamp = ({ style }) => {
    const data = useStaticQuery(graphql `
    query {
      lamp: file(relativePath: { eq: "about-us/lamp.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)
    return <Img style = { style }
    fluid = { data.lamp.childImageSharp.fluid } alt="Lamp"
    />
}

export default Lamp