import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const BarsGraph = ({ style }) => {
    const data = useStaticQuery(graphql`
    query {
        barsGraph: file(relativePath: { eq: "about-us/bars_graph.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)
    return <Img style={style}
        fluid={data.barsGraph.childImageSharp.fluid} alt="Bars Graph"
    />
}

export default BarsGraph