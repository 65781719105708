import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'

const BluePrint = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
        bluePrint: file(relativePath: { eq: "about-us/blueprint.png" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)
  const imageData = data.bluePrint.childImageSharp.fluid
  return (
    <BackgroundImage
      Tag="section"
      fluid={imageData}
      style={{
        minHeight: `100vh`
      }}
    >
      <div style={{ minHeight: `100vh` }}>
        {children}
      </div>
    </BackgroundImage>
  )
}

export default BluePrint