import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'

const StreetMovement = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
        streetMovement: file(relativePath: { eq: "about-us/street_movement.png" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)
  const imageData = data.streetMovement.childImageSharp.fluid
  return (
    <BackgroundImage
      Tag="section"
      fluid={imageData}
      style={{
        minHeight: `100vh`
      }}
    >
      <div style={{ backgroundColor: `rgb(0, 0, 0, 0.5)`, minHeight: `100vh` }}>
        {children}
      </div>
    </BackgroundImage>
  )
}

export default StreetMovement