import React from "react"
import Styled from "styled-components"

const Title = Styled.h1`
writing-mode: vertical-lr;
font-size: 7vw;
@media (max-width: 768px){
    display: none;
}
`
const TitleMobile = Styled.h1`
font-size: 7vw;
text-align: center;
@media (min-width: 768px){
    display: none;
}
`

const VerticalTitle = ({ style, children }) => (
    <div>
        <Title style={style}>{children}</Title>
        <TitleMobile style={style}>{children}</TitleMobile>
    </div>
)

export default VerticalTitle