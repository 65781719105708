import React, { Component } from "react"
import gsap from "gsap"
import { ScrollTrigger } from 'gsap/src/ScrollTrigger'

class Highlight extends Component {
  componentDidMount() {

    gsap.registerPlugin(ScrollTrigger);

    gsap.utils.toArray(".text-highlight-purple").forEach((highlight) => {
      ScrollTrigger.create({
        trigger: highlight,
        start: "center center",
        onEnter: () => highlight.classList.add("active"),
      });
      ScrollTrigger.refresh();
    });

    gsap.utils.toArray(".text-highlight-black").forEach((highlight) => {
      ScrollTrigger.create({
        trigger: highlight,
        start: "-100px center",
        onEnter: () => highlight.classList.add("active"),
      });
      ScrollTrigger.refresh();
    });

    gsap.utils.toArray(".text-highlight-yellow").forEach((highlight) => {
      ScrollTrigger.create({
        trigger: highlight,
        start: "-100px center",
        onEnter: () => highlight.classList.add("active"),
      });
      ScrollTrigger.refresh();
    });

  }
  render() {
    return (
      <div>
        {this.props.children}
      </div>
    )
  }
}

export default Highlight