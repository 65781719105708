import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Globe = ({ style }) => {
    const data = useStaticQuery(graphql `
    query {
      globe: file(relativePath: { eq: "about-us/globe.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)
    return <Img style = { style }
    fluid = { data.globe.childImageSharp.fluid } alt="Globe"
    />
}

export default Globe